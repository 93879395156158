@import 'styles/localStylesIndex.scss';

:global {
  .Toastify__toast {
    min-height: 30px;
    max-width: 630px;
    display: flex;
    justify-content: center;

    background-color: palette(white);
    border: 1px solid $border;
    border-radius: 7.5px;
    box-shadow: 0 1.5px 7px 0 rgba(77, 73, 122, 0.15);
    color: palette(black);
    font-size: 14px;

    padding: 15px 30px;
  }

  .Toastify__toast--info {
    border-color: palette(grey);
    color: palette(black);
  }
  .Toastify__toast--success {
    border-color: palette(green);
    color: palette(green);
  }
  .Toastify__toast--warning {
    border-color: palette(yellow);
    color: palette(yellow);
  }
  .Toastify__toast--error {
    border-color: palette(red);
    color: palette(red);
  }
}
