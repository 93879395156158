@import 'styles/_config.scss';

.tabNoOverflow {
  overflow-y: initial;
}

.error {
  color: $danger;
}

.passwordTabRoot {
  padding: 12px 30px 30px;
  margin-top: 12px;
  width: 100%;

  .password {
    max-width: 320px;
  }
}

.itemsAddNew {
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 5px;
  }
}

.formikFix {
  margin-top: -30px;

  & > * {
    margin-top: 18px;
  }
}

.formikFixBP {
  & > * {
    margin-top: 18px;
  }
}

.accessRightsRoot {
  width: 100%;

  .role {
    width: calc(100% / 3);
    padding-right: 20px;
    max-width: 350px;

    .roleHeading {
      margin-left: 5px;
    }
  }

  .permissionsHeading {
    margin-left: 5px;
    margin-top: 25px;
    margin-bottom: -20px;
    text-align: center;
  }

  hr {
    border-top: 1px $border-color solid;
  }

  .permissionsTitle {
    text-transform: capitalize;
  }

  .bottomFlex {
    margin-top: 10px;
    display: flex;
    width: 100%;

    & > div {
      flex: 1;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .listRow {
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    height: 100%;
    border-bottom: 1px solid $border-color;
  }

  @include respond-below('md') {
    .role {
      width: 100%;
      padding-right: 0;
      max-width: 100%;
    }

    .bottomFlex {
      display: block;

      & > div {
        margin-right: 0;
      }
    }
  }
}

.profileCards {
  width: 100%;
  display: flex;

  & > div {
    flex-grow: 1;
    margin-right: 1%;
    max-width: 33%;
    @include respond-below('lg') {
      max-width: 100%;
    }
  }

  &.pwd > div {
    flex-grow: 1;
    margin-right: 1%;
    max-width: 33%;
    @include respond-below('lg') {
      max-width: 50%;
    }
    @include respond-below('md') {
      max-width: 100%;
    }
  }

  @include respond-below('md') {
    flex-direction: column;
    & > div {
      flex-grow: 1;
      margin-right: 0;
      margin-top: 2%;

    }
  }

  .fields {
    margin-top: -15px;
    padding: 20px;

    & > div, & > button {
      margin-top: 15px;
    }
  }

}

:global {
  .select .wSelect__control {
    border-color: $grey-light !important;
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
  }
  .select .wSelect__control .wSelect__single-value {
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: italic;
  }
  .select .wSelect__indicators {
    display: none;
  }
}

.profileTabRoot {
  padding: 12px 30px 30px;
  margin-top: 12px;
  width: 100%;

  & > div {
    width: 100%;
    display: flex;

    & > div {
      margin-right: 35px;
      flex-grow: 1;
      max-width: 320px;
      margin-bottom: 18px;

      & > * {
        margin-bottom: 18px;
      }

      & > :last-child {
        margin-top: 7px;
      }
    }
  }

  @include respond-below('md') {
    & > div {
      display: block;

      & > div {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}

.userManagement {
  width: 100%;

  .chip {
    margin-left: 10px;
  }
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tableTitle {
  font-size: 14px;
  line-height: 18px;

  .email {
    color: $blue;
    font-size: 12px;
    line-height: 16px;
    margin: 2px 0 0 0;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button:first-child {
    margin-right: 12px;
  }
}
