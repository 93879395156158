@import 'styles/_config.scss';

.root {
  background-color: $primary;
  width: 300px;
  flex-shrink: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: margin-left $animation-speed ease, margin-right $animation-speed ease;

  .logo {
    display: flex;
    margin: 30px 20px 16px 10px;
    align-items: center;
    justify-content: space-between;
    height: 38px;

    .webswingLogo {
      z-index: 1;
    }
    img {
      height: 70px;
      margin-right: 10px;
    }

    .title {
      font-size: 17px;
      color: white;
    }

    .subtitle {
      font-size: 9px;
      color: $border-color;
    }
  }

  .companyLogo {
    margin: 25px 0 0px 30px;
    flex: 1;
    //margin-top: -20px;

    img {
      width: 90%;
    }
  }

  .menuFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //height: calc(100% - 180px);

    flex: 1;
    .menuItems {
      padding-left: 0;
    }
  }

  .subItems {
    .menuItem {
      > div {
        margin-left: 75px;
      }
    }
  }

  .developed {
    font-size: 12px;
    line-height: 16px;
    margin-left: 34px;
    color: $neutral;

    a {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .menuCloseIcon {
    display: none;
    cursor: pointer;
  }

  .menuItem {
    display: flex;
    align-items: center;
    color: $txt-dark;
    height: 50px;
    position: relative;

    .icon {
      margin-left: 35px;
    }

    .text {
      margin-left: 16px;
      color: $border-color;
    }

    &:hover,
    &.activeLink {
      .text {
        font-weight: 600;
        color: white;
      }

      .icon {
        color: $txt;
      }

      &:before {
        content: '';
        position: absolute;
        height: 48px;
        width: 3px;
        border-radius: 4px;
        background-color: $blue;
      }
    }
  }

  @include respond-below('sm') {
    & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      z-index: 200;
      margin-left: -300px;

      &.show {
        margin-left: 0;
      }

      .menuCloseIcon {
        display: block;
        //margin-top: -20px;
      }
    }
  }
}
