@import 'styles/_config.scss';

.root {
  margin: 0 75px;
  padding-bottom: 40px;

  .logoFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }

  .centerMiddle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1
  }

  .success {
    color: $success;
  }

  .card {
    overflow: hidden;
  }

  .cardFlex {
    display: flex;
    overflow: hidden;
    justify-content: space-between;

    img {
      width: 50%;
    }

    .registerContent {
      padding: 55px 160px 60px 80px;
      flex: 1;

      .input {
        margin-bottom: 18px;
      }

      .spinner {
        margin-left: 5px;
      }

      .registerButton {
        padding: 0 24px;
      }

      .checkbox {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > a {
          text-align: right;
        }
      }
    }

    @include respond-below('lg') {
      .registerContent {
        padding-right: 80px;
      }
    }

    @include respond-below('md') {
      .registerContent {
        padding: 45px 45px 80px 45px;
      }
    }
  }

  @include respond-below('sm') {
    .cardFlex img {
      display: none;
    }
  }

  @include respond-below('xs') {
    & {
      margin: 0 15px;

      .logoFlex .logo img {
        height: 60px;
      }

      .cardFlex .registerContent {
        padding: 30px 30px 50px 30px;
      }
    }
  }

  @include respond-below('xxs') {
    .logoFlex .logo img {
      height: 50px;
      margin-left: -10px;
    }

    .cardFlex .registerContent .checkbox {
      display: block;
      > div {
        margin-bottom: 10px;
      }
    }
  }
}
