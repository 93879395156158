@import 'styles/_config.scss';

// Here is where the magic happens

$offset: 187;
$duration: 1.4s;

.wSpinner {
  animation: rotator $duration linear infinite;
  stroke: palette(primary);
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  animation: dash $duration ease-in-out infinite;
  // colors ($duration*4) ease-in-out infinite;
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
}

@keyframes colors {
  0% {
    // stroke: palette(primary);
  }

  // 25% {
  //     stroke: #DE3E35;
  // }

  // 50% {
  //     stroke: #F7C223;
  // }

  // 75% {
  //     stroke: #1B9A59;
  // }

  100% {
    // stroke: palette(primary);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
