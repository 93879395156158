// Colors
$primary: #0c1726;
$primaryLight: #3b91ec;
$primaryDark: #0a1725;

$neutral: #90969c;

$bgPrimary: #282c34;

$danger: #ff2437;
$danger-light: #ff4a54;

$success: #6dd400;
$success-light: #32c83299;

$black: #000;
$blackest: #333;

$grey: grey;
$grey-light: lightgrey;
$grey-lighter: #d9d9d9;
$grey-cold: rgb(132, 138, 144);

$blue: #0099cf;
$blue-light: #81c9e2;
$blue-hightlight: rgb(65,152,202);

$warning: #f7b500;
$warning-light: rgba(255, 229, 100, 0.6);

$white: #fff;

$border: #e4e9ed;

$border-color: #e5e5e6;
$border-radius: 4px;
$border-radius-big: 8px;

$iconColorActive: $grey;
$iconColorInactive: $primary;

//
// Backgrounds
$bgLight: rgba(0, 0, 0, 0.2);
$bg-color: #f5f5f5;

//
// Text
$txt: #606771;
$txt-dark: #313a47;

//
// Offsets (paddings & margins)

//
// Proportions (widths & heights)

//
// Shadows
$shadow-light: 0 1px 4px 0 rgba(77, 73, 122, 0.15);

// animation
$animation-speed: 0.3s;

//
// Fonts
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}
