@import 'styles/_config.scss';

.wNoData {
  align-items: center;
  color: palette(grey);
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  opacity: 0.8;
  width: 100%;

  i,
  img,
  svg {
    margin-bottom: 20px;
  }

  span {
    font-size: 18px;
  }
}
