@import 'styles/_config.scss';

.root {
  z-index: 10;

  .player,
  .player * {
    outline: none;
    background-color: transparent;
  }

  .owen,
  .owenMobile {
    position: fixed;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .owenMobile {
    bottom: 20px;
    right: 10px;
    padding: 8px 15px;

    span {
      display: flex;
      align-items: center;
      line-height: 20px;
      font-size: 16px;

      & > *:first-child {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  @include respond-below(sm) {
    .owen {
      display: none;
    }
  }
  @include respond-above(sm) {
    .owenMobile {
      display: none;
    }
  }
}

@keyframes main {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.dialogWrap {
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;

  & > div {
    width: 800px;
    height: 500px;
    margin: 20px;
    min-width: auto !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 20px !important;
    flex-direction: row !important;
    animation: main 0.3s;
    transform-origin: 100% 100%;

    & > div {
      position: relative;
      padding: 0 !important;
      max-height: 100%;
    }

    @include respond-below(sm) {
      height: 90%;
      margin: 10px;
    }
  }
}

$messagePaddingHorizontal: 16px;
$messagePaddingVertical: 10px;

.thumbnail {
  margin: (-$messagePaddingVertical) (-$messagePaddingHorizontal);

  a {
    height: 160px;
    display: block;
  }

  img {
    height: 160px;
    width: 160px;
    object-fit: cover;
  }
}

.player {
  button {
    display: none !important;
  }
}

:global {
  .video-react-poster {
    background-repeat: repeat !important;
  }

  .video-react-video:focus {
    outline: none;
  }
}

.dialog {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .stunt {
    height: 100%;
    background-color: $primary;
    padding-top: 20px;
    display: block;
    flex-basis: 240px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px 0 0 20px;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  top: -30px;
  background-color: #0c172688;
  border-radius: 12px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 16px;
  color: white;
  outline: none;
  border: none;
}

.stuntText {
  color: white;
  transition: padding-bottom 0.3s ease-in-out;
  height: 0;
  // margin-bottom: 0;
  max-width: 150px;
  overflow: hidden;
  text-align: center;

  &.shownText {
    padding-bottom: 40px;
  }
}

@include respond-above(xl) {
  .dialog .stunt {
    flex-basis: 300px;
    padding-top: 25px;

    .player {
      width: 250px !important;
      height: 407px !important;
    }
  }

  .dialogWrap {
    & > div {
      width: 1000px;
      height: 600px;
    }
  }
}

@include respond-below(md) {
  .dialog .stunt {
    display: none;
  }
}

// CHAT

$br-small: 8px;
$br-big: 20px;

.chatWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;

  .chat {
    display: flex;
    padding: 24px;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1;
    text-decoration: none !important;

    .message {
      background-color: $bg-color;
      overflow: hidden;
      border-radius: $br-small $br-big $br-big $br-small;
      margin: 2px 0;
      margin-right: auto;
      padding: $messagePaddingVertical $messagePaddingHorizontal;
      width: fit-content;
      max-width: 85%;
      flex-shrink: 0;

      &:first-of-type {
        margin-top: 8px;
        border-top-left-radius: $br-big;
      }

      &:last-of-type {
        margin-bottom: 8px;
        border-bottom-left-radius: $br-big;
      }
    }

    .group {
      display: flex;
      align-items: flex-end;

      .avatar {
        margin: 0 8px 8px;
        flex-shrink: 0;
      }

      & > div:last-child {
        flex-grow: 1;
      }

      .content {
        font-size: 14px;
        line-height: 20px;
        color: black;
        margin: 0;
        word-break: break-word !important;

        a {
          padding: 4px 10px;
          background: rgba(0, 153, 207, 0.2);
          border-radius: 12px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }

      &.own {
        flex-direction: row-reverse;

        .avatar {
          margin: 0 0 8px 8px;
        }

        .message {
          background-color: $blue;
          border-radius: $br-big $br-small $br-small $br-big;
          margin-left: auto;
          margin-right: 0;

          &:first-of-type {
            margin-top: 8px;
            border-top-right-radius: $br-big;
          }

          &:last-of-type {
            margin-bottom: 8px;
            border-bottom-right-radius: $br-big;
          }

          .content,
          .content a {
            color: white;
          }
        }
      }
    }

    &.openedBlur {
      filter: blur(1.5rem);
      opacity: 0.5;
    }

    @include respond-below(sm) {
      padding: 24px 10px;
    }
  }

  .optionsButton {
    padding: 0 24px 24px;

    &.select {
      text-align: right;
    }

    .options {
      position: absolute;
      bottom: 78px;
      right: 24px;

      &.textareaOptions {
        bottom: 95px;
      }

      h5 {
        color: #666666;
      }
    }

    h5 {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;

      text-transform: uppercase;

      color: #c9c9c9;
      margin: 8px 0 0;
    }

    .buttonWrap {
      display: flex;
      margin: 8px 0;

      .form {
        display: flex;
        flex: 1;
      }

      &.float {
        float: right;
      }

      button {
        padding: 8px 8px 8px 16px;
        border-radius: 18px;
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        background-color: white;
        float: right;
        margin-left: 8px;

        &.sendButton {
          padding: 8px;
          margin-left: 8px;
          border: none;
        }

        .optionsIcon {
          margin-left: 5px;
          transition: transform 0.3s ease-in-out;

          &:not(.opened) {
            transform: rotate(45deg);
          }
        }
      }

      &.hideOptionsText {
        button {
          padding: 6px;
          margin-left: 4px;
          border: none;

          &.sendButton {
            padding: 6px;
          }

          .optionsIcon {
            margin-left: 0;

            &:not(.opened) {
              transform: rotate(0);
            }

            &.opened {
              transform: rotate(45deg) !important;
            }
          }
        }
      }

      .responseInput {
        flex-grow: 1;

        input,
        textarea {
          resize: none;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          border-radius: 18px;
          font-style: italic;
          padding: 9px 16px;
          font-size: 14px;
          line-height: 18px;
          -webkit-appearance: none;
          color: #666666;
        }
      }
    }

    @include respond-below(sm) {
      padding: 0 18px 24px;
    }
  }
}

.optionsChipWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-right: -4px;

  &.anchorsChipWrap {
    justify-content: flex-start;
    margin: 8px 0;
  }

  .optionsChip {
    padding: 7px 16px;
    margin: 4px 4px;
    font-weight: 500;
    cursor: pointer;
    border-color: $border-color;
    background-color: white;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }

    &.answer {
      cursor: default;
      &:hover {
        box-shadow: none;
      }
    }

    span {
      font-size: 14px;
      line-height: 18px;
      color: black;
    }

    &.selected {
      border-color: transparent;
      background-color: $blue;
      span {
        color: white;
      }
    }

    &.disableHover:hover {
      cursor: default;
      box-shadow: none;
    }
  }
}

.right {
  text-align: right;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.typeBlock {
  align-items: center;
  display: flex;
  height: 17px;
}

.typeDot {
  background-color: #90949c;
}

.typeDot {
  animation: typing 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.typeDot:nth-child(1) {
  animation-delay: 200ms;
}
.typeDot:nth-child(2) {
  animation-delay: 300ms;
}
.typeDot:nth-child(3) {
  animation-delay: 400ms;
}
