@import 'styles/_config.scss';

.wTable {
  display: flex;
  flex: 1 1 auto;
  min-width: calc(100vw - 900px);
  min-height: calc(100vh - 150px);
  max-width: calc(100vw - 350px);

  @include respond-below('sm') {
    max-width: initial;
  }

  .wHeaderCell {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;

    span {
      font-size: type-scale(0);
    }

    i,
    img,
    svg {
      display: inline;
      margin-left: 10px;
    }
  }

  .wCell {
    align-items: flex-start;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  // .column {
  //   background-color: red;
  // }

  & > div {
    height: auto !important;
  }

  & > div.loader {
    height: 2px !important;
  }
}

.tableHeader {
  display: flex;
  align-items: center;

  & > div {
    margin-left: 15px;
    font-size: 16px;
    color: $primary;
    font-weight: bold;
  }
}

.horizontalScroll {
  flex: 1;
  width: 100%;
  overflow-x: scroll;
  padding: 0;

  & > div {
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
}

.noScroll {
  overflow: hidden !important;
}

:global {
  .ReactVirtualized__Table__Grid {
    background-color: white;
    box-shadow: $shadow-light;
    border-radius: $border-radius-big;
    border: 1px solid $border-color;
    outline: none;
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid $border-color;
    outline: none;
  }

  .ReactVirtualized__Table__row.pointer {
    cursor: pointer;
  }

  .ReactVirtualized__Table__rowColumn {
    margin: 0 15px !important;
    //flex-basis: 0 !important;
    display: flex;
  }
}
