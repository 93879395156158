@import 'styles/_config.scss';

.wButtonDefault {
  cursor: pointer;
  transition: all $animation-speed ease-in-out;

  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled,
  &:disabled:active {
    cursor: default;
    opacity: 0.6 !important;

    label {
      cursor: default;
    }
  }

  label {
    cursor: pointer;
    font-size: type-scale(0);
    font-weight: 600;
  }
}

.bLoading {
  cursor: wait !important;
}

.large {
  height: 56px;
  border-radius: 28px;

  label {
    font-size: 16px;
    line-height: 19px;
  }
}

.medium {
  height: 44px;
  border-radius: 22px;

  label {
    font-size: 16px;
    line-height: 19px;
  }
}

.small {
  height: 31px;
  border-radius: 16px;

  label {
    font-size: 13px;
    line-height: 16px;
  }
}

.danger {
  background-color: $danger;
  border: 2px solid $danger-light;
  padding: 0 20px;

  label,
  i {
    color: white;
  }
}

.blue {
  background-color: $blue;
  border: none;
  padding: 0 20px;

  label,
  i {
    color: white;
  }
}

.neutral {
  border: 2px solid $border-color;
  background-color: white;
  padding: 0 20px;

  label {
    color: $txt-dark;
  }

  i {
    color: $txt;
  }
}

.transparent:hover,
.transparent:active,
.transparent:focus {
  &.danger {
    background-color: transparent;
    border: 2px solid $danger;

    label,
    i {
      color: $danger;
    }
  }

  &.blue {
    background-color: transparent;
    border: $blue;

    label,
    i {
      color: white;
    }
  }

  &.neutral {
    border: 2px solid white;
    background-color: $border-color;

    label, i {
      color: $border-color;
    }
  }
}

.shadow:hover,
.shadow:focus,
.shadow:active {
  &.danger {
    box-shadow: 4px 10px 20px 0 transparentize($danger, 0.5);
  }

  &.blue {
    box-shadow: 4px 10px 20px 0 transparentize($blue, 0.5);
  }

  &.neutral {
    box-shadow: 4px 10px 20px 0 transparentize($border-color, 0.5);
  }
}

.icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-width: 1px;

  &.small {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 36px;
    height: 36px;
  }

  &.large {
    width: 48px;
    height: 48px;
  }
}
