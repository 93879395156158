@import 'styles/_config.scss';

.wInputField {
  //   display: flex;
  //   flex: 1;
  position: relative;
  width: 100%;

  .errorMessage {
    bottom: -20px;
    right: 0;
    color: palette(red);
    display: block;
    font-size: type-scale(-1);
    position: absolute;
    text-align: start;
  }
}
