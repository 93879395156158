@import 'styles/_config.scss';

$itemsPerRow1: 100%;
$itemsPerRow2: 50%;
$itemsPerRow3: 33.333%;
$itemsPerRow4: 25%;
$itemsPerRow5: 20%;
$itemsPerRow6: 16.666%;
$itemsPerRow7: 14.285%;
$itemsPerRow8: 12.5%;
$itemsPerRow9: 11.111%;
$itemsPerRow10: 10%;
$itemsPerRow10: 8.33%;

$spacing1: 4px;
$spacing2: 8px;
$spacing3: 12px;
$spacing4: 16px;
$spacing5: 20px;
$spacing6: 24px;
$spacing7: 28px;
$spacing8: 32px;
$spacing9: 36px;
$spacing10: 40px;

.wGrid {
  display: flex;
  box-sizing: border-box;

  &.wRow {
    flex-direction: row;

    &.spacing {
      & > * {
        // &:first-child {
        //   margin-left: unset;
        // }

        &:last-child {
          margin-right: unset;
        }
      }
    }

    &.spacing05 {
      & > * {
        margin-right: ($spacing1 / 2);
      }
    }

    &.spacing1 {
      & > * {
        margin-right: $spacing1;
      }
    }

    &.spacing2 {
      & > * {
        margin-right: $spacing2;
      }
    }

    &.spacing3 {
      & > * {
        margin-right: $spacing3;
      }
    }

    &.spacing4 {
      & > * {
        margin-right: $spacing4;
      }
    }

    &.spacing5 {
      & > * {
        margin-right: $spacing5;
      }
    }

    &.spacing6 {
      & > * {
        margin-right: $spacing6;
      }
    }

    &.spacing7 {
      & > * {
        margin-right: $spacing7;
      }
    }

    &.spacing8 {
      & > * {
        margin-right: $spacing8;
      }
    }

    &.spacing9 {
      & > * {
        margin-right: $spacing9;
      }
    }

    &.spacing10 {
      & > * {
        margin-right: $spacing10;
      }
    }

    &.itemsPerRow1 {
      & > * {
        width: $itemsPerRow1;
      }
    }

    &.itemsPerRow2 {
      & > * {
        width: $itemsPerRow2;
      }
    }

    &.itemsPerRow3 {
      & > * {
        width: $itemsPerRow3;
      }
    }

    &.itemsPerRow4 {
      & > * {
        width: $itemsPerRow4;
      }
    }

    &.itemsPerRow5 {
      & > * {
        width: $itemsPerRow5;
      }
    }

    &.itemsPerRow6 {
      & > * {
        width: $itemsPerRow6;
      }
    }

    &.itemsPerRow7 {
      & > * {
        width: $itemsPerRow7;
      }
    }

    &.itemsPerRow8 {
      & > * {
        width: $itemsPerRow8;
      }
    }

    &.itemsPerRow9 {
      & > * {
        width: $itemsPerRow9;
      }
    }

    &.itemsPerRow10 {
      & > * {
        width: $itemsPerRow10;
      }
    }
  }

  &.wColumn {
    flex-direction: column;

    &.spacing {
      & > * {
        // &:first-child {
        //   margin-top: unset;
        // }

        &:last-child {
          margin-bottom: unset;
        }
      }
    }

    &.spacing05 {
      & > * {
        margin-bottom: ($spacing1 / 2);
      }
    }

    &.spacing1 {
      & > * {
        margin-bottom: $spacing1;
      }
    }

    &.spacing2 {
      & > * {
        margin-bottom: $spacing2;
      }
    }

    &.spacing3 {
      & > * {
        margin-bottom: $spacing3;
      }
    }

    &.spacing4 {
      & > * {
        margin-bottom: $spacing4;
      }
    }

    &.spacing5 {
      & > * {
        margin-bottom: $spacing5;
      }
    }

    &.spacing6 {
      & > * {
        margin-bottom: $spacing6;
      }
    }

    &.spacing7 {
      & > * {
        margin-bottom: $spacing7;
      }
    }

    &.spacing8 {
      & > * {
        margin-bottom: $spacing8;
      }
    }

    &.spacing9 {
      & > * {
        margin-bottom: $spacing9;
      }
    }

    &.spacing10 {
      & > * {
        margin-bottom: $spacing10;
      }
    }
  }
}
