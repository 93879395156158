@import 'styles/_config.scss';

$verticalTime: 1.3s;
$horizontalTime: 1.6s;
$rowColor: lightgrey;

.tableSkeleton {
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.item {
  height: 40px;
  background-color: $rowColor;
  background-image: linear-gradient(90deg, $rowColor 0px, palette(white) 20%, $rowColor 40%);
  background-size: 200% 200%;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 1px;
  width: 100%;
}

.itemAnimation {
  // old animation - top to bottom
  // -webkit-animation: animation $verticalTime ease infinite;
  // -moz-animation: animation $verticalTime ease infinite;
  // animation: animation $verticalTime ease infinite;

  animation: LinearWave $horizontalTime infinite linear;
  -webkit-animation: LinearWave $horizontalTime infinite linear;
  -moz-animation: LinearWave $horizontalTime infinite linear;
}

@keyframes animation {
  0% {
    background-position: 0px;
  }

  50% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes LinearWave {
  0% {
    background-position: 100%;
  }

  50% {
    background-position: 0%;
  }

  100% {
    background-position: -100%;
  }
}

//
//  Variants
//
// Default
// .wSkeletonUI {}
// .wSkeletonCircle {}
// .wSkeletonLine {}
// .wSkeletonSquare {}
// .wSkeletonTable {}
