@import 'styles/_config.scss';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-below('sm') {
    margin-left: 25px;
  }

  .pageHeaderTitle {
    display: flex;
    align-items: center;

    & > button {
      margin-right: 15px;
    }
  }
}
