@import 'styles/_config.scss';

.root {
  display: block;
  width: 1em;
  line-height: 1em;
  overflow: hidden;

  // color
  &.primary {
    color: $primary;
  }
  &.white {
    color: #ffffff;
  }
  &.light {
    color: $light;
  }
  &.blue {
    color: $blue;
  }
  &.neutral {
    color: $neutral;
  }
  &.success {
    color: $success;
  }
  &.warning {
    color: $warning;
  }
  &.danger {
    color: $danger;
  }
  &.black {
    color: black;
  }

  // sizes
  &.mini {
    font-size: 16px;
  }
  &.small {
    font-size: 20px;
  }
  &.medium {
    font-size: 24px;
  }
  &.large {
    font-size: 28px;
  }
  &.extraLarge {
    font-size: 38px;
  }
}
