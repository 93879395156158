@import 'styles/_config.scss';

.root {
  display: inline-block;
  position: relative;

  .badge {
    position: absolute;
  }

  &.top {
    .badge {
      // size
      &.small {
        top: -2px;
      }
      &.medium {
        top: -5px;
      }
      &.large {
        top: -8px;
      }
    }
  }

  &.bottom {
    .badge {
      // size
      &.small {
        bottom: -8px;
      }
      &.medium {
        bottom: -4px;
      }
      &.large {
        bottom: 8px;
      }
    }
  }

  // side
  &.left {
    .badge {
      right: 100%;

      &.small {
        margin-right: -8px;
      }
      &.medium {
        margin-right: -12px;
      }
      &.large {
        margin-right: -14px;
      }
    }
  }
  &.right {
    .badge {
      left: 100%;

      &.small {
        margin-left: -8px;
      }
      &.medium {
        margin-left: -12px;
      }
      &.large {
        margin-left: -12px;
      }
    }
  }
}
