@import 'styles/_config.scss';

.wInfoMessage {
  border-left: 10px solid palette(grey);
  color: palette(black);
  display: flex;
  // flex: 1;
  padding: 20px 45px 20px 25px;
  text-align: left;
  width: 100%;
}

.info {
  background-color: palette(grey, light);
  border-left-color: palette(grey);
}
.warning {
  background-color: palette(yellow, light);
  border-left-color: palette(yellow);
}
.error {
  background-color: palette(red, light);
  border-left-color: palette(red);
}
.success {
  background-color: palette(green, light);
  border-left-color: palette(green);
}
