@import 'styles/_config.scss';

// CSS for the top Select wrapper
.wSelect {
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;

  // CSS for the Select's label
  label {
    display: flex;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    color: $txt-dark;
    font-weight: 600;
  }
}

// CSS for individual parts of the Select
// this is linked to the custom `classNamePrefix` prop
// below is a list of all Select's subcomponents taken from the inspector
:global {
  // Inner container of the Select
  .wSelect__control {
    border-color: $neutral !important;
    box-shadow: none !important;
    // ...

    .wSelect--is-disabled {
      opacity: 0.7;
      color: $neutral;
      background-color: white;
      border-color: $border-color;
    }

    // Wrapper containing value of the Select
    .wSelect__value-container {
      height: 44px;
      // Select's placeholder
      .wSelect__placeholder {
        font-size: 14px;
        line-height: 18px;
        color: $txt;
      }

      // Select's input wrapper, right below this `div` is the `input`
      .wSelect__input {
        // ...

        input {
          border: 1px solid $neutral;
          border-radius: 3px;

          font-size: 14px;
          line-height: 18px;
          color: $txt;

          padding: 14px 16px;
        }
      }
    }

    // Dropdown icon on the far right
    .wSelect__indicators {
      // The little vertical line separating Select's input and the dropdown icon
      // (it's a `<span />` with bg color and 1px width)
      .wSelect__indicator-separator {
        display: none;
      }

      // Dropdown icon wrapper, below this is only the SVG icon
      .wSelect__indicator.wSelect__dropdown-indicator {
        // ...

        // The actual dropdown icon
        svg {
        }
      }
    }
  }

  .wSelect__menu {
    .wSelect__menu-list {
      // ...
      padding: 0 !important;

      // CSS for Select's individual options, it's just a `div` containing option's label
      .wSelect__option {
        color: palette(black);
      }

      // CSS class applied when an option is being hovered-over
      .wSelect__option--is-focused {
        background-color: $bg-color;
      }

      // CSS class applied when an option is selected
      .wSelect__option--is-selected {
        background-color: $bg-color;
      }
    }
  }
}
