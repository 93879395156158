@import 'styles/index.scss';

.root {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff;

  &.new {
    background-color: #ee5c4e;
  }
  &.opened {
    background-color: #006ae1;
  }
  &.assigned {
    background-color: #f0ad4e;
  }
  &.processing {
    background-color: #5cb85c;
  }
  &.waiting {
    background-color: #f0ad4e;
  }
  &.resolved {
    background-color: #006ae1;
  }
  &.closed {
    background-color: #808080;
  }
  &.reopened {
    background-color: #ee5c4e;
  }
  &.backlog {
    background-color: #006ae1;
  }
  &.expired {
    background-color: #ccc;
  }
}
