@import 'styles/_config.scss';

.statisticsDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px;

  .statisticsRow {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;

    & > div {
      flex: 1 0 21%;
      min-width: 200px;
      margin: 5px;

      .label {
        color: $neutral;
        line-height: 18px;
      }

      .text {
        color: $txt-dark;
        font-size: 24px;
        line-height: 30px;
        margin-top: 4px;
      }

      .by {
        margin-bottom: -10px;
      }
    }
  }
}

table.renewalDates {
  width: 100%;
  min-width: 300px;

  @include respond-below('lg') {
    min-width: unset;
  }

  thead tr {
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    white-space: nowrap;
  }

  th, td {
    padding: 2px 5px;
  }

  td {
    font-weight: normal;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }
}

.folder {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  color: $primary;
  font-weight: 600;

  &:hover {
    background-color: $border-color;
  }

  .avatar {
    margin-right: 20px;
    flex-shrink: 0;
    background-color: white !important;
  }
}

.widgets {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;

  & > div {
    padding: 5px;
    width: 50%;

    @include respond-below('md') {
      width: 100%;
    }
  }

  @include respond-below('sm') {
    & {
      display: block;
    }
  }
}

.cards {
  min-height: 280px;
  @include respond-below('md') {
    min-height: unset;
  }
}

.player,
.player * {
  outline: none;
  background: white !important;
}

.email {
  color: $blue;
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
}

.detail {
  @include respond-below('sm') {
    text-align: center;
    * {
      text-align: center;
    }
  }
}

.scheduleCall {
  margin-top: 20px;
  margin-bottom: -66px;
  width: 100%;
  text-align: center;
}

.button {
  margin-top: 8px;
}

.latestNews {
  cursor: pointer;

  .title {
    @include respond-below('sm') {
      font-size: 1.1rem !important;
    }
  }

  .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    @include respond-below('sm') {
      display: none;
    }
  }
}
.avatarFull {
  @include respond-below('sm') {
    width: 100% !important;
    height: auto !important;
    margin-right: 0 !important;
  }
}

.licenseContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-right: 1rem;

  .licenseHeading {
    text-transform: capitalize;
    margin: 4px 0 15px !important;
  }

  .company {
    color: $neutral;
    margin-bottom: 9px;

    
  }

  .webswing {
    color: $blue-light;
  }

  .licenseButton {
    margin-left: 10px;
    flex-shrink: 0;
  }

  .contactUs {
    display: flex;
    align-items: center;
  }
}

.standard {
  background-color: $blue;
  border-radius: $border-radius-big;
  color: white;
  height: 100%;
  min-height: 280px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  .company {
    color: white;
    margin-bottom: 9px;
  }

  img {
    height: 160px;
    border-radius: $border-radius;
    @include respond-below('lg') {
      display: none;
    }
    @include respond-below('md') {
      display: inherit;
    }
  }



}

.inputs {
  //margin-top: -60px;
  display: flex;
  flex-direction: column;

  .input {
    //margin-top: 30px;
    margin-bottom: 10px;
    width: 427px;
  }

  .wFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    & > button {
      margin-left: 5px;
    }
  }
}

.phoneSupportWidget {
  cursor: default;
  background-color: rgb(14, 23, 37);
  background-image: url('../../../assets/images/support-24.7-bg.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 280px;
  padding: 0.75rem 0.75rem 0.75rem 75px;

  @include respond-below('md') {
    height: unset;
  }

  @include respond-below('sm') {
    background-position: top left;
    background-size: 30px;
    padding: 0.75rem;
  }

  .phoneSupportHead {
    color: $white;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .phoneSupportContent {
    color: $grey-cold;
    font-size: 0.85rem;
    &.mb {
      margin-bottom: 1rem;
    }
    &.mb05 {
      margin-bottom: 0.5rem;
    }
    &.info {
      display: flex;

      > div:first-child {
        width: 25%;
      }

      .blue {
        color: $blue-hightlight;
      }

      .white {
        color: $white;
      }
    }

    .badge {
      display: inline-flex;
      align-items: center;
      border-radius: 0.25rem;
      padding: 2px 8px;
      font-size: 0.8rem;
      margin-top: 0.5rem;

      .icon {
        margin-right: 0.5rem;
      }

      &.red {
        background-color: rgb(56, 34, 46);
        color: rgb(240, 78, 81);
      }

      &.blue {
        background-color: rgb(20, 48, 70);
        color: rgb(85, 159, 223);
      }
    }
  }
}
