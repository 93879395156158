@import 'styles/_config.scss';

$paddingInner: 10px;

.wCard {
  background-color: white;
  box-shadow: $shadow-light;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-big;
  text-align: left;
  border: 1px solid $border-color;
}

.heading {
  margin-left: 5px;
}

.clickable {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: scale(1.05);
  }
}

.cardContent {
  padding: 22px 25px;
}
