@import 'styles/_config.scss';

.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  opacity: 0;
  visibility: hidden;
  transition: opacity $animation-speed linear, visibility 0s linear $animation-speed;

  &.visible {
    opacity: 0.01;
    visibility: visible;
    transition: opacity $animation-speed linear;
  }
}
