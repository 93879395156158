@import 'styles/_config.scss';

.folder {
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid $border-color;
  padding: 0 16px;
  cursor: pointer;

  &:hover {
    background-color: $border-color;
  }

  i {
    margin-right: 15px;
  }
}

.rowPreview {
  cursor: not-allowed;
  color: $neutral;
}

.folderCell {
  cursor: pointer;
  height: 100%;
  width: 100%;

  &.preview {
    cursor: not-allowed;
    color: $neutral;
  }

  a {
    color: $txt;

    &:hover {
      text-decoration: underline;
    }
  }
}

.fileName {
  display: flex;
  align-items: center;

  i {
    //margin-right: 15px;
    min-width: 30px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
