@import './_colors.module.scss';

// ==========================
// Config
// ==========================

$font: '';
$iconSize: 12px;

$breakpoints: (
  'xxs': 375px,
  'xs': 550px,
  'sm': 769px,
  'md': 992px,
  'lg': 1200px,
  'xl': 1380px,
);

// Respond above.
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// --------------------------
// Color palette map
// --------------------------
// 1. set basic palette
// 2. set common semantic variables
// 3. use semantic one only when it makes sense ($border-color for border
//    property)
// 4. use directly from palette otherwise (palette(gray, light); for section
//    background
//
// http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
$palettes: (
  white: (
    base: $white,
  ),
  grey: (
    light: $grey-light,
    base: $grey,
  ),
  black: (
    base: $black,
    background: $bgPrimary,
    backgroundLight: $bgLight,
  ),
  primary: (
    base: $primary,
    light: $primaryLight,
    dark: $primaryDark,
  ),
  red: (
    base: $danger,
    light: $danger-light,
  ),
  green: (
    base: $success,
    light: $success-light,
  ),
  yellow: (
    base: $warning,
    light: $warning-light,
  ),
);

@function palette($color, $tone: 'base') {
  @if map-has-key($palettes, $color) {
    $color: map-get($palettes, $color);

    @if map-has-key($color, $tone) {
      $tone: map-get($color, $tone);
      @return $tone;
    }

    @warn "unknown tone `#{$tone}` in color";
    @return null;
  }

  @warn "unknown color `#{$color}` in palette";
  @return null;
  // @return map-get(map-get($palettes, $color), $tone);
}

// --------------------------
// Spacing and vertical rhythm
// --------------------------
$base-line-height: 24px; // base unit for vertical rhythm

$spacing-xxxs: ($base-line-height / 4); // 6px, 0.5 line
$spacing-xxs: ($base-line-height / 2); // 12px, 1 line
$spacing-xs: ($base-line-height); // 24px, 2 lines
$spacing-s: ($base-line-height / 2 * 3); // 36px, 3 lines
$spacing: ($base-line-height * 2); // 48px, 4 lines
$spacing-m: ($base-line-height * 3); // 72px, 6 lines
$spacing-l: ($base-line-height * 4); // 96px, 8 lines

// Font weights
// --------------------------
$light: 300;
$normal: 400;
$bold: 700;

// Complete type scale
// --------------------------
$font-settings: (
  -1: (
      font-size: 12px,
      line-height: (
        12/12,
      ),
    ),
  0: (
    font-size: 14px,
    line-height: (
      18/14,
    ),
  ),
  1: (
    font-size: 16px,
    line-height: (
      24/16,
    ),
  )
);

@function type-scale($level) {
  //
  // #TODO @OD: add a check for existing SCSS variables
  //

  @return map-get(map-get($font-settings, $level), 'font-size');
}

@function line-height($level) {
  @return map-get(map-get($font-settings, $level), 'line-height');
}

@mixin type-setting($level) {
  font-size: type-scale($level);
  line-height: line-height($level);
}

// --------------------------
// Z-indexes
// --------------------------
// Please update documentation accordingly

$zIndex: (
  min: 1,
  tooltip: 3,
  overlay: 5,
  dialog: 12,
  toast: 13,
  max: 14,
);

@function zIndex($name) {
  @if map-has-key($zIndex, $name) {
    @return map-get($zIndex, $name);
  }

  @warn 'The key #{$name} is not in the map ’$zIndex’';
  @return null;
}

// .m-lightbox {
//   z-index: zIndex(lightbox);
// }

// --------------------------
// Border radius
// --------------------------
// Please update documentation accordingly

$radius: (
  button: 3px,
  input: 2px,
  box: 2px,
);

@function radius($name) {
  @if map-has-key($radius, $name) {
    @return map-get($radius, $name);
  }

  @warn 'The key #{$name} is not in the map ’$radius’';
  @return null;
}

// Example
// .class {
//   border-radius: radius(button);
// }
