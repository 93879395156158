@import 'styles/_config.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;

  @include respond-below('sm') {
    flex-wrap: wrap;
    justify-content: center;
  }

  .title {
    color: $primary;
    min-width: 0;
    flex: 1;

    @include respond-below('sm') {
      flex: auto;
      width: 100%;
    }
  }

  .subtitle {
    margin-top: 5px;
    color: $neutral;
    min-width: 0;
  }

  .avatar {
    margin-right: 20px;
    flex-shrink: 0;

    @include respond-below('sm') {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }

  &.huge {
    .title {
      font-size: 22px;
      line-height: 21px;
    }
    .subtitle {
      font-size: 17px;
      line-height: 18px;
    }
    .avatar {
      width: 120px;
      height: 120px;
    }
  }

  &.large {
    .title {
      font-size: 18px;
      line-height: 21px;
    }
    .subtitle {
      font-size: 14px;
      line-height: 18px;
    }
    .avatar {
      width: 100px;
      height: 100px;
    }
  }

  &.medium {
    .title {
      font-size: 16px;
      line-height: 19px;
    }
    .subtitle {
      font-size: 13px;
      line-height: 16px;
    }
    .avatar {
      width: 60px;
      height: 60px;
    }
  }

  &.small {
    .title {
      font-size: 13px;
      line-height: 16px;
    }
    .subtitle {
      font-size: 11px;
      line-height: 13px;
    }
    .avatar {
      width: 40px;
      height: 40px;
    }
  }
}
