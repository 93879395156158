@import 'styles/_config.scss';

.wStepper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .wHeader {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 10px;

    &.titlesOffset {
      padding: 10px 25px 25px 25px;
    }

    .wStep {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      position: relative;
      text-align: center;
      white-space: nowrap;

      .wStepIndex {
        align-items: center;
        border: 1px solid palette(grey, light);
        border-radius: 50%;
        color: palette(grey);
        display: flex;
        //   flex: 1;
        height: 30px;
        justify-content: center;
        max-width: 30px;
        min-width: 30px;

        &.active {
          background-color: palette(primary, light);
          border-color: transparent; // otherwise the circles lose 2px's and UI jumps
          color: palette(white);
        }

        &.aActive {
          animation: pulse 2s infinite;
        }
      }

      span {
        bottom: -20px;
        font-size: 14px;
        position: absolute;
      }
    }

    .connector {
      border-top: 1px solid palette(grey, light);
      width: 100%;

      &.active {
        border-color: palette(primary);
      }
    }

    .withMargin {
      margin: 0 10px;
    }
  }

  .wStepContent {
    display: flex;
    flex: 1;
    padding: 10px;
  }

  .wFooter {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .bFooter {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 145, 236, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(59, 145, 236, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(59, 145, 236, 0);
  }
}
