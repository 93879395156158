@import 'styles/_config.scss';

.wTab {
  background-color: palette(white);
  border: 1px solid $border-color;
  padding: 5px 20px;
  border-radius: $border-radius;
  margin-right: 7px;

  i,
  span,
  svg {
    color: $txt-dark;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  // Disables :hover class below when Tab's disabled
  &:disabled {
    i,
    span,
    svg {
      color: palette(grey) !important;
    }
  }

  &:hover {
    i,
    span,
    svg {
      color: palette(primary, light);
    }
  }

  &.active {
    background-color: $blue;

    i,
    span,
    svg {
      color: palette(white);
    }
  }

  //
  // Icon positions
  //
  &.i-left {
    flex-flow: row;

    i,
    svg {
      margin-right: 10px;
    }
  }

  &.i-top {
    flex-direction: column;

    i,
    svg {
      margin-bottom: 10px;
    }
  }

  &.i-right {
    flex-flow: row-reverse;

    i,
    svg {
      margin-left: 10px;
    }
  }
}

.wTabs {
  display: inline-block;
  width: 100%;

  .wTabsRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;

    div:first-child {
      flex-shrink: 0;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;

      button {
        flex-shrink: 0;
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }
  }

  .wTabContent {
    //display: flex;
    //overflow: scroll;
    //flex: 1;
    height: 100%;
    width: 100%;
  }
}
