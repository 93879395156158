@import 'styles/_config.scss';

.buttons {
  display: flex;
}
.headSection,
.marginBottom {
  margin-bottom: 2rem;
}
.heading2 {
  margin-left: 5px;
}
.headerTable {
  color: $primaryLight;
  span {
    font-size: 12px !important;
  }
}

.filesTable,
.wsTable {
  min-height: 300px;
}

.aBTable,
.wsTable,
.javaTable {
  margin-bottom: 2rem;
}

.aBTable {
  min-height: 400px;
}

.borderless {
  border: none;
}

.disabled {
  color: $border-color;
  cursor: not-allowed;
  i {
    color: $border-color !important;
  }
}

.full {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.fontSmall {
  font-size: 12px;
}

.javaTable {
  min-height: 91px;
}

.listStyle {
  padding: 0;
  margin: 0;
  list-style: none;
  > li {
    display: flex;
    align-items: center;
    &::before {
      content: '\27A4';
      color: $blue;
      font-size: 20px;
    }
  }
}
.listRow {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: 10px 20px;
  height: 100%;
  i {
    color: $neutral;
  }
}

.fileCell {
  display: flex;
  align-items: center;
  cursor: pointer;
  > i {
    margin-right: 3px;
  }
}

.note {
  color: $neutral;
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.btnSpacing {
  margin: 1rem 0;
}

.viewerStyle {
  background-color: $bg-color;
  border: 1px solid $border;
  border-radius: $border-radius;
  color: $white;
  padding: 1rem;
  max-width: 100%;
  overflow-x: auto;
  font-family: monospace;
}

.contentLocked {
  position: relative;
  width: 100%;
  border: 1px dashed #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: $border-radius-big;
  background-color: $bg-color;
  overflow: hidden;
  position: relative;

  img {
    cursor: auto !important;
  }

  h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 8px;
  }

  p {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
  }
}

.space {
  margin-bottom: 1rem;
}
