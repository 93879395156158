@import 'styles/_config.scss';

.wFullscreen {
  align-items: center;
  cursor: wait;
  background: palette(black, backgroundLight);
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: zIndex(max);
}
