@import 'styles/_config.scss';

.root {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .h1 {
    line-height: 30px;
    font-size: 24px;
    margin: 24px 0;
  }

  .h2 {
    line-height: 21px;
    font-size: 18px;
    margin: 18px 0;
  }

  .h3 {
    line-height: 20px;
    font-size: 16px;
    margin: 15px 0;
  }

  .rightTitle {
    margin-right: 5px;
  }
  .rightFlex {
    display: flex;
    align-items: center;
  }

  .buttonsWrap {
    flex-shrink: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding-left: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    position: relative;
    transition: background-color $animation-speed ease, border-radius $animation-speed ease-out;

    & > * + * {
      margin-left: 10px;
    }
  }
}
