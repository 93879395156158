@import 'styles/_config.scss';

$FABSize: 35px;
$animSpeed: 250ms;
$positionOffset: 100px;

.wFAB {
  align-items: center;
  display: flex;
  position: fixed;

  .bMain {
    height: $FABSize + 10px !important;
    width: $FABSize + 10px !important;
  }

  .bSub {
    margin: 10px;
    transform: scale(0);
  }

  .bSubShow {
    animation: animationShow $animSpeed ease-in-out;
    animation-fill-mode: forwards;
    -webkit-animation: animationShow $animSpeed ease-in-out;
    -moz-animation: animationShow $animSpeed ease-in-out;
  }

  .bSubHide {
    animation: animationHide $animSpeed ease-in-out;
    animation-fill-mode: backwards;
    -webkit-animation: animationHide $animSpeed ease-in-out;
    -moz-animation: animationHide $animSpeed ease-in-out;
  }
}

.FAB {
  background-color: palette(white);
  border-radius: 50%;
  cursor: pointer;
  height: $FABSize;
  width: $FABSize;
}

.bDisabled {
  cursor: default;
  opacity: 0.6;
}

//
// ========== Animations ==========
//
@keyframes animationShow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes animationHide {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

//
// ========== Positions ==========
//
.topRight {
  right: $positionOffset;
  top: $positionOffset;
}

.bottomRight {
  bottom: $positionOffset;
  right: $positionOffset;
}

.bottomLeft {
  bottom: $positionOffset;
  left: $positionOffset;
}

.topLeft {
  left: $positionOffset;
  top: $positionOffset;
}
