@import 'styles/_config.scss';

.root {
  display: flex;
  width: 100%;

  .menuIcon {
    position: absolute;
    color: $primary;
    width: 60px;
    height: 60px;
    display: none;
    padding-top: 20px;
    margin-bottom: -10px;
    margin-left: -10px;
    cursor: pointer;
    z-index: 10;
  }

  @include respond-below('sm') {
    & {
      display: block;

      .menuIcon {
        display: block;
      }
    }
  }
}
