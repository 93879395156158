@import 'styles/_config.scss';

.loaderLine {
  border-radius: 4px;
  height: 2px !important;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 100;
  //   opacity: 0.6;

  div {
    animation: loading 1.2s linear infinite;
    background-color: palette(primary);
    height: 100%;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
}

@keyframes loading {
  0% {
    transform: translate3d(0, 0, 0) scaleX(1);
  }

  50% {
    transform: translate3d(100%, 0, 0) scaleX(0.2);
  }

  80% {
    transform: translate3d(-80%, 0, 0) scaleX(1);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}
