.wList {
  display: flex;
  flex: 1;
  overflow: auto;

  .wListRegular {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
