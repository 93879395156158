@import 'styles/_config.scss';

.circleProgress {
  display: block;
  margin: auto;
}

.background {
  stroke: #e6e6e6;
}

.foreground {
  stroke: $blue;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.text{
  font-size: 10px;
  fill: #90969c;
}