@import 'styles/_config.scss';

.footer {
  font-size: type-scale(-1);
  &.center {
    text-align: center;
  }
}

.hide {
  display: none;
}

.followWrapper {
  &.center {
    justify-content: center;
    padding-left: 2em;
  }

  display: flex;
  padding-right: 25px;
  margin-top: 1em;

  div {
    padding-top: 3px;
    svg {
      margin: 0 0.5em;
    }
  }
}

.root {
  margin: 0 75px;
  padding-bottom: 40px;

  .logoFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }

  .card {
    overflow: hidden;
  }

  .cardFlex {
    display: flex;
    justify-content: space-between;

    img {
      width: 50%;
    }

    .loginContent {
      flex: 1;
      padding: 55px 160px 60px 80px;

      .input {
        margin-bottom: 18px;
      }

      .logInButton {
        padding: 0 24px;
      }

      .spinner {
        margin-left: 5px;
      }

      .checkbox {
        margin: 20px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > a {
          text-align: right;
        }
      }
    }

    @include respond-below('lg') {
      .loginContent {
        padding-right: 80px;
      }
    }

    @include respond-below('md') {
      .loginContent {
        padding: 45px 45px 80px 45px;
      }
    }
  }

  @include respond-below('sm') {
    .cardFlex img {
      display: none;
    }
  }

  @include respond-below('xs') {
    & {
      margin: 0 15px;

      .logoFlex .logo img {
        height: 60px;
      }

      .cardFlex .loginContent {
        padding: 30px 30px 50px 30px;
      }
    }
  }

  @include respond-below('xxs') {
    .logoFlex .logo img {
      height: 50px;
      margin-left: -10px;
    }

    .cardFlex .loginContent .checkbox {
      display: block;
      > div {
        margin-bottom: 10px;
      }
    }
  }
}
