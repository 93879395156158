// @import 'styles/_config.scss';
@import 'styles/_config.scss';

// Whole Input wrapper
.wInput {
  display: flex;
  flex-direction: column;
}

// Label wrapper
.wLabel {
  display: flex;
  margin-bottom: 4px;

  // Label text
  .tLabel {
    font-size: 12px;
    line-height: 16px;
    color: $txt-dark;
    font-weight: 600;

    //&.disabled {
    //  color: $neutral;
    //}
  }
}

// Input field
.fInput {
  border: 1px solid $neutral;
  border-radius: 3px;

  font-size: 14px;
  line-height: 18px;
  color: $txt;

  padding: 13px 16px;

  &.hidden {
    padding: 0;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled {
    //opacity: 0.7;
    color: $blackest;
    background-color: $grey-lighter;
    //border-color: $border-color;
    cursor: not-allowed;
  }

  // Hides the HTML up/down arrows for numbered inputs
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.inputDiv {
  display: flex;
  align-items: center;
  position: relative;

  .visibility {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  input {
    flex-grow: 1;
  }
}

// Error text
.tError {
  color: palette(red);
  font-size: type-scale(0);
}

// Clear Icon (X)
.iClear {
  position: absolute;
  right: 5px;
  top: 16px;
}
