@import 'styles/_config.scss';

.wCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 3px;
  }

  label {
    cursor: pointer;
    font-size: type-scale(0);
    margin-left: 10px;
    color: $txt;
  }
}
