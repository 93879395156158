@import 'styles/_config.scss';

.wChip {
  align-items: center;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 4px 15px;

  // Label
  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  div {
    margin-left: 10px;
  }
}

.bDisabled {
  opacity: 0.6;
}

.fill {
  &.white {
    background-color: #ffffff;
    color: $txt;
  }
  &.neutral {
    background-color: $border-color;
    color: $neutral;
  }
  &.blue {
    background-color: #d9f0f8;
    color: $blue;
  }
  &.primary {
    background-color: $primary;
  }
  &.success {
    background-color: $success;
  }
}

.outline {
  border: 1px solid palette(primary, light);
}
