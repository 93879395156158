@import 'styles/_config.scss';

.content {
  min-height: 100vh;
  max-width: 100vw;
  display: block;
  flex-grow: 1;
  background-color: $bg-color;
  padding: 0 25px 40px;

  @include respond-above('md') {
    & {
      max-width: calc(100vw - 300px);
    }
  }
}
