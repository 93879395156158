@import 'styles/_config.scss';

.wErrorBoundry {
  .wError {
    display: flex;
    flex-direction: column;
    font-size: type-scale(0);

    span {
      color: palette(red);
      margin-right: 15px;
    }
  }
}
