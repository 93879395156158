@import 'styles/_config.scss';

.root {
  z-index: 100;

  .avatar {
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 20px;
  }

  .popover {
    .userInfoTitle {
      padding: 20px 25px;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .subtitle {
        color: $neutral;
        margin-bottom: 5px;
      }

      .email {
        color: $blue;
      }
    }

    .logout {
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      justify-content: space-between;
      padding: 16px 25px;
    }

    @include respond-above('sm') {
      & {
        top: 70px;
        right: 26px !important;
      }
    }
  }
  @include respond-below('sm') {
    .avatar {
      top: 20px;
    }
  }
}
