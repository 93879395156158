@import 'styles/_config.scss';

.root {
  display: block;
  width: 22px;
  height: 22px;
  margin: 0 1px;
  padding: 0;
  border-radius: 3px;
  flex-shrink: 0;
  background-color: #ffffff;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color $animation-speed ease, border-color $animation-speed ease,
    box-shadow $animation-speed ease;

  .checkIcon {
    position: absolute;
    top: 1px;
    left: 1px;
    opacity: 0;
    font-size: 18px;
    color: #ffffff;
    transition: opacity $animation-speed ease;
  }

  &:hover,
  &:focus,
  &:active {
    // colors
    &.primary {
      box-shadow: 0 0 0 4px transparentize($primary, 0.7);
    }
    &.neutral {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.24);
    }
    &.success {
      box-shadow: 0 0 0 4px rgba(76, 217, 100, 0.5);
    }
    &.warning {
      box-shadow: 0 0 0 4px rgba(255, 204, 0, 0.5);
    }
    &.danger {
      box-shadow: 0 0 0 4px rgba(255, 96, 89, 0.5);
    }
  }

  &.inverted {
    background: none;
    border-color: $border-color;

    &:hover,
    &:focus,
    &:active {
      border-color: $bg-color;
    }

    &.checked {
      border-color: $primary;
    }
  }

  &.checked {
    .checkIcon {
      opacity: 1;
    }

    // colors
    &.primary {
      background-color: $primary;
      border-color: $primary;
    }
    &.neutral {
      background-color: $neutral;
      border-color: $neutral;
    }
    &.success {
      background-color: $success;
      border-color: $success;
    }
    &.warning {
      background-color: $warning;
      border-color: $warning;
    }
    &.danger {
      background-color: $danger;
      border-color: $danger;
    }
  }

  &.disabled {
    border-color: $bg-color;
    cursor: not-allowed;
    box-shadow: none;

    &.inverted {
      border-color: #404040;

      &.checked {
        background-color: #656565;
        border-color: #656565;
      }
    }

    &.checked {
      background-color: $bg-color;

      .checkIcon {
        opacity: 1;
        color: #ffffff;
      }
    }
  }
}
