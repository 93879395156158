@import 'styles/_config.scss';

.wBackground {
  align-items: center;
  background: palette(black, backgroundLight);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;

  position: absolute;
  top: 0;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: zIndex(dialog);

  .wDialog {
    background: white;
    display: flex;
    flex-direction: column;
    //min-height: 100px;
    min-width: 400px;
    border-radius: $border-radius;

    transform: translate(0, 0) scale(1);
    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);

    .wHeader {
      padding: 20px 20px;
      display: flex;
      justify-content: space-between;
      span {
        font-weight: bold;
        font-size: 1.25em;
      }

      i {
        cursor: pointer;
      }
    }

    .wContent {
      //display: flex;
      flex: 1;
      padding: 0 20px;
    }

    .wFooter {
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px;
      & > button {
        margin-left: 5px;
      }
    }
  }
}
