.btnGoogle {
  background-color: #4285F4;
}

.btnFacebook {
  background-color: #1877F2;
}

.btnLinkedin {
  background-color: #0077B5;
}

.btnGithub {
  background-color: #181717;
}
