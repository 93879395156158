@import 'styles/_config.scss';

.refDiv {
  position: absolute;

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.root {
  transition: width $animation-speed ease-in;
  width: 0;
  position: relative;
  z-index: 500;

  .card {
    overflow: hidden;
    border: 0px;
    border-radius: $border-radius-big;

    .boxInverted {
      background-color: black;
      border-radius: $border-radius-big;
    }

    .content {
      height: 100%;
      max-height: 500px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }

  &.left {
    float: left;

    .card {
      border-top-left-radius: $border-radius;
    }
  }

  &.right {
    float: right;

    .card {
      border-top-right-radius: $border-radius;
    }
  }

  &.open {
    transition: width $animation-speed ease-out;

    .card {
      border: 1px solid $border-color;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
@include respond-above('sm') {
  .refDiv {
    top: 0;
  }
}

@include respond-below('sm') {
  .refDiv {
    width: 100% !important;
    bottom: 0 !important;
  }

  .root {
    transition: none;
    position: fixed;

    bottom: 0 !important;

    &.left,
    &.right {
      .card {
        border-radius: $border-radius-big $border-radius-big 0 0;
      }
    }

    &.open {
      transition: none;
    }

    .content {
      width: 100% !important;
      max-width: 100%;
    }
  }
}
