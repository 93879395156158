@import 'styles/_config.scss';

$border: 1px solid $border-color;

.wDivider {
  border-bottom: $border;
  width: 100%;
}

.wVertical {
  border-right: $border;
  height: 100%;
}
