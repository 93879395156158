@import 'styles/_config.scss';

//
// Was just copied from Volante
// ! NEEDS MAINTENANCE
//

.wSwitch {
  align-items: center;
  cursor: pointer;
  display: flex;

  label {
    color: $txt-dark;
    cursor: pointer;
    font-size: type-scale(0);
    margin-left: 15px; // +5 due to the thumb
    position: relative;
  }
}

.toggle {
  align-items: center;
  cursor: pointer;
  height: 26px;
  display: flex;
  justify-content: center;
  width: 42px;
  padding: 0;
  position: relative;

  .slider {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 12px;
    height: 26px;
    width: 42px;
  }

  .thumb {
    background-color: $neutral;
    border: 1px solid white;
    border-radius: 50%;
    box-sizing: border-box;
    height: 26px;
    left: -1px;
    position: absolute;
    top: 0px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate3d(0, 0, 0);
    width: 26px;
  }

  .thumbActive {
    background-color: $success;
    border-color: white;
    transform: translate3d(17px, 0, 0);
  }
}

.disabled {
  opacity: 0.6;

  &,
  label,
  .toggle {
    cursor: not-allowed;
  }
}
