@import 'styles/_config.scss';

.root {
  align-items: center;
  line-height: 20px;
  color: $txt;
  font-size: 14px;
  font-weight: 600;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  &.horisontalScroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    & > * {
      flex-shrink: 0;
    }
  }

  a {
    text-decoration: none;
  }

  .item {
    color: white;
    padding: 5px 15px;
    border-radius: 16px;
    background-color: $blue;
    font-size: 12px;
    line-height: 14px;

    &:last-child {
      background-color: white;
      color: $txt;
    }
  }

  .separator {
    margin: 0 5px;
    color: $txt;
    font-size: 20px;
  }
}
