@import 'styles/_config.scss';

// Dimensions
$small: 24px;
$medium: 40px;
$large: 56px;
$huge: 100px;

.wAvatar {
  align-items: center;
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  overflow: hidden;

  .wImg {
    height: 100%;
    width: 100%;
  }

  // Label
  span {
    color: palette(black);
    font-size: 1.25rem;
  }
}

.wAvatarGroup {
  display: flex;

  .wAvatarStack {
    margin-right: -10px;
  }
}

//
//  Shape variants
//
.circle {
  border-radius: 50%;
}
.round {
  border-radius: 15%;
}
.square {
  border-radius: $border-radius;
}

//
//  Size variants
//
.small {
  height: $small;
  width: $small;
}
.medium {
  height: $medium;
  width: $medium;
}
.large {
  height: $large;
  width: $large;
}

.huge {
  height: $huge;
  width: $huge;
}
