@import 'styles/_config.scss';

.root {
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  overflow: hidden;
  display: inline-block;

  // size
  &.small {
    line-height: 12px;
    height: 12px;
    width: 12px;
    font-size: 9px;
  }

  &.medium {
    line-height: 16px;
    height: 16px;
    width: 16px;
    font-size: 10px;
  }

  &.large {
    line-height: 24px;
    height: 24px;
    width: 24px;
    font-size: 12px;
  }

  &.white {
    background-color: #ffffff;
    color: $txt;
  }
  &.neutral {
    background-color: $neutral;
  }
  &.blue {
    background-color: $blue;
  }
  &.primary {
    background-color: $primary;
  }
  &.success {
    background-color: $success;
  }
}
