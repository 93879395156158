@import 'styles/_config.scss';

.buttons {
  display: flex;
}


.inputs {
  margin-top: -60px;
  display: flex;
  flex-direction: column;

  .input {
    margin-top: 30px;
    width: 400px;
  }

  .wFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    & > button {
      margin-left: 5px;
    }
  }
}

:global {
  .rdw-editor-wrapper {
    margin: 1rem 0;
    max-width: 100%;
    max-height: calc(100vh - 225px);
    overflow-y: auto;
  }

  .rdw-editor-toolbar {
    visibility: visible;
    position: sticky;
    top: 0;
    z-index: 2;
    border: 0 !important;
    padding: 8px !important;
    background: #efefef !important;
  }
  .rdw-option-wrapper {
    padding: 15px !important;
  }

  .rdw-editor-main {
    border: 1px $neutral solid;
    padding: 10px;
  }
}

.modal {
  min-width: 75% !important;
}

button.bug {
  background-color: $danger !important;
  position: relative;
  padding-right: 3rem;
  cursor: default;

  span {
    color: $white !important;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    color: white;
    border: 0;
    border-left: 1px white solid;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      background-color: $danger-light !important;
      * {
        color: white !important;
      }
    }
  }
}

.status {
  display: flex;
  align-items: center;
  text-transform: capitalize;

  div {
    margin-right: 15px;
  }
}

.chip {
  margin-left: 10px;
}

.table {
  min-height: auto;
  max-height: calc(100vh - 200px);
}

.tableHeader {
  display: flex;
  align-items: center;

  & > div {
    margin-left: 15px;
    font-size: 16px;
    color: $primary;
    font-weight: bold;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.files {
  display: flex;
  flex-direction: column;

  div {
    padding: 2px 0;
  }
}

.link {
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

.ticketDetails {
  display: flex;

  .leftSide {
    flex: 1;
    margin-right: 15px;
  }

  .ticketMessage {
    flex: 2;
    min-height: 100%;

    .ticketMessageHeader {
      display: flex;
      align-items: center;
      height: 50px;

      & > div {
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    .ticketHeadingContent {
      padding-top: 10px;
      padding-bottom: 20px;
      cursor: pointer;

      .inboxDiv {
        display: flex;
        align-items: center;

        p {
          color: $txt-dark;
        }

        div {
          margin-right: 10px;
        }
      }

      .reciever {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .recieverInfo div {
          &:not(:last-child) {
            margin-bottom: 8px;
          }

          strong {
            color: $neutral;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .text {
    font-size: 16px;
    line-height: 28px;
    white-space: pre-line;
    word-break: break-word;
  }

  @include respond-below(md) {
    & {
      display: block;

      .ticketMessage {
        .ticketMessageHeader {
          margin-top: 20px;
        }
      }

      .leftSide {
        margin-right: 0;
      }
    }
  }

  @include respond-below(xs) {
    & {
      display: block;

      .ticketMessage {
        .ticketMessageHeader {
          display: block;
        }
      }
    }
  }
}

.ticketDetailsTop {
  padding-bottom: 16px;
}

.ticketDetailsBottom {
  padding-top: 0;
}

.attachment {
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid $border-color;
  padding: 0 16px;
  //cursor: pointer;

  .attachmentChip {
    width: 65px;
    margin-right: 15px;
    cursor: pointer;
  }

  .fileInfo {
    color: $neutral;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
  }

  i {
    margin-right: 15px;
  }
}

.textWrapper {
  margin-top: 14px;

  .label {
    font-size: 12px;
    line-height: 16px;
    color: $neutral;
    margin-bottom: 2px;
  }

  .text {
    color: $txt-dark;
    font-size: 14px;
    line-height: 18px;
  }
}

.dateChip {
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.subject {
  max-width: 150px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.chipWithDate {
  height: 34px;
  border-radius: 16px;
  padding: 4px 4px 4px 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $border-color;
  font-size: 12px;
  color: $neutral;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emails {
  height: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  border-radius: $border-radius-big;
  border-bottom: 1px solid $border-color;

  & div:first-child {
    margin-top: 0;
  }

  & > div {
    margin-top: 10px;
  }
}

.contacts {
  display: flex;

  & > div {
    display: flex;
    width: 100%;

    & > div {
      height: 22px;
      margin-left: 10px;
    }
  }
}

.filename {
  max-width: 253px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
}

.attachments {
  height: 100%;
  //max-height: calc(100vh - 590px);
  max-height: 300px;
  overflow-y: auto;
}

.tabsTicket {
  position: relative;
  > div {
    margin-bottom: 11px !important;
  }
  > div > div {
    padding: 0 !important;
    > button {
      border-radius: 16px;
    }
  }
}

.headerFiles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 60px;
  border-bottom: 1px solid $border-color;
}
.fileIcon {
  cursor: pointer;
}
.removeFile {
  margin-left: auto;
  i {
    margin-right: 0px;
    font-size: 16px!important;
  }
}

.file {
  &:hover {
    background-color: $border-color;
  }
}

.attachmentsTop {
  margin-top: 16px
}

.Valchip {
  margin-top: 10px;
  max-width: 650px;
}

.resolved {
  margin-top: 1rem;
}

.flex {
  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
  }
}