@import 'styles/_config.scss';

.wRadio {
  align-items: center;
  display: flex;

  cursor: pointer;

  input {
    cursor: pointer;
  }

  label {
    cursor: pointer;
    font-size: type-scale(0);
    margin-left: 10px;
  }
}
